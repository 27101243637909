
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
@font-face {
  font-family: 'ls-icon';
  src: url('../fonts/ls-icon-v12.eot');
  src:
    url('../fonts/ls-icon-v12.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ls-icon-v12.woff') format('woff'),
    url('../fonts/ls-icon-v12.woff2') format('woff2'),
    url('../fonts/ls-icon-v12.ttf') format('truetype'),
    url('../fonts/ls-icon-v12.svg#ls-icon') format('svg');
  font-display: swap;
}

.ls-icon {
  font-family: 'ls-icon';
}
// DONT ADD ANY NEW STYLES IN THIS GLOBAL CSS
